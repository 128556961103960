<style scoped>
.picturebackground {
  background-color: #cccccc;
  height: 200px;
  background-size: cover;
}
</style>

<template>
  <div>
    <div class="columns is-fullhd">
      <div class="column">
        <div class="box">
          <div class="bold">Werkplaatsen</div>
          Wij geloven in de kracht van ervaringsleren, in de verbinding tussen
          je hoofd, hart en handen. Wekelijks zijn er verschillende werkplaatsen
          en onderdelen die terugkerend op het programma staan. Het aanbod
          verandert geregeld, zodat we zo goed mogelijk aansluiten bij de groep
          jongeren die er aanwezig is. Hieronder zie je een lijst met de
          werkplaatsen die (afwisselend) aangeboden worden, klik door voor meer
          informatie.
        </div>
        <div class="columns is-fullhd">
          <div class="column">
            <article
              class="picturebackground pointer notification is-primary"
              :style="
                'background-image: url(' +
                getPictureLink('werkplaatsen_sport') +
                ')'
              "
              @click="$router.push({ name: 'sport-en-spel' })"
            >
              <p class="title">Sport & Spel</p>
              <p class="subtitle">Sociale spelen</p>
            </article>
          </div>
          <div class="column">
            <article
              class="picturebackground pointer notification is-primary"
              :style="
                'background-image: url(' +
                getPictureLink('werkplaatsen_hetleerhuis') +
                ')'
              "
              @click="$router.push({ name: 'het-leerhuis' })"
            >
              <p class="title">Het leerhuis</p>
              <p class="subtitle"></p>
            </article>
          </div>
          <div class="column">
            <article
              class="picturebackground pointer notification is-primary"
              :style="
                'background-image: url(' +
                getPictureLink('werkplaatsen_horeca') +
                ')'
              "
              @click="$router.push({ name: 'horeca' })"
            >
              <p class="title">Horeca</p>
              <p class="subtitle">Koken en bakken</p>
            </article>
          </div>
        </div>
        <div class="columns is-fullhd">
          <div class="column">
            <article
              class="picturebackground pointer notification is-primary"
              :style="
                'background-image: url(' +
                getPictureLink('werkplaatsen_buiten') +
                ')'
              "
              @click="$router.push({ name: 'buiten' })"
            >
              <p class="title">Buiten</p>
              <p class="subtitle">Tuin en klus</p>
            </article>
          </div>
          <div class="column">
            <article
              class="picturebackground pointer notification is-primary"
              :style="
                'background-image: url(' +
                getPictureLink('werkplaatsen_kunst') +
                ')'
              "
              @click="$router.push({ name: 'kunst' })"
            >
              <p class="title">Kunst</p>
              <p class="subtitle"></p>
            </article>
          </div>
          <!-- <div class="column">
            <article
              class="picturebackground pointer notification is-primary"
              @click="$router.push({ name: 'gast-aan-tafel' })"
              :style="
                'background-image: url(' +
                  getPictureLink('werkplaatsen_gastaantafel') +
                  ')'
              "
            >
              <p class="title">Huis en haard</p>
              <p class="subtitle"></p>
            </article>
          </div> -->
        </div>
      </div>
      <div class="column">
        <carrousel :page="'werkplaatsen_alle_werkplaatsen'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      env: process.env,
    };
  },
  computed: {
    pictures() {
      return this.$store.getters["pictures"];
    },
  },
  methods: {
    getPictureLink(category) {
      const pictures = this.pictures.filter((a) => a.category === category);

      if (pictures.length) {
        return (
          this.env.VUE_APP_API_URL + "/web/site-pictures/" + pictures[0].uuid
        );
      }
    },
  },
  mounted() {},
};
</script>
