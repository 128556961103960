<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Horeca</div>
          Bij Het Dagelijks Bestaan zijn we gek op eten. Iedere dag verzorgen we
          zelf een lunch. Een aantal keren per week gebeurt dit onder
          begeleiding van een professionele kok. We houden van koken met lokale
          gewassen en seizoensgebonden producten.
          <br />
          <br />
          <div class="bold">Bakken</div>
          Het maken van allerlei zoets doen we ook graag; taarten, cakes en
          koekjes! We vieren graag verjaardagen of afscheid met heerlijke taart.
          We bakken niet alléén voor onszelf, we trakteren anderen ook graag op
          onze baksels. Daarnaast kun je onze koekjes zomaar tegenkomen naast je
          kopje koffie bij de IJsselstroom!
        </div>
      </div>
      <div class="column">
        <carrousel :page="'werkplaatsen_horeca'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
