<style scoped>
.customTitle {
  font-weight: bold;
}

.customBox {
  border-style: solid;
}
</style>

<template>
  <div>
    <quote
      quote="Op Het Dagelijks Bestaan springen de levensvragen zo op je schouder"
    ></quote>
    <br />
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Woongemeenschap</div>
          Op het hofje van Het Dagelijks Bestaan staan 26 woningen, van studio’s
          tot gezinswoningen. Veertien woningen zijn voor jongeren met een
          zorgvraag, de andere twaalf woningen worden bewoond door de buren. We
          wonen met een bont gezelschap, van studenten tot jonge gezinnen. De
          basis van onze gemeenschap bestaat uit het delen van
          gemeenschappelijke, biologische maaltijden. We drinken thee of koffie
          en vieren bijzondere gebeurtenissen. We werken in de tuin, klussen wat
          op het terrein en verzorgen de buitenruimte. We hebben oog voor onze
          omgeving en hechten belang aan het ontwikkelen van rituelen en
          gewoontes.
          <br />
          <br />
          We zijn met elkaar op weg om uit te zoeken hoe gemeenschappelijk wonen
          eigenlijk werkt. We denken niet dat we het antwoord te weten komen,
          maar hebben wel zin het samen verder uit te pluizen. Affiniteit met
          jongeren is erg belangrijk. De jonge mensen die hier wonen landen voor
          een periode van een (aantal) jaar bij ons in de gemeenschap. Je krijgt
          de kans om een stukje mee op te lopen in hun zoektocht naar een
          passende plek in de maatschappij. Daarnaast worden zij begeleid door
          een team van professionals.
          <br />
          <br />
          <!-- <br />
          Op dit moment zijn er geen woningen beschikbaar bij de woongemeenschap
          van Het Dagelijks Bestaan.<br /><br /> -->
          <a
            class="button is-primary is-fullwidth is-rounded"
            @click="$router.push({ name: 'nieuwe-bewoner' })"
            >Klik hier om de oproep voor deze woning te bekijken</a
          >
          <br />
          Heb je een zorgvraag? Kijk dan bij
          <a @click="$router.push({ name: 'beschermd-wonen' })"
            >Beschermd wonen</a
          >.
        </div>
      </div>
      <div class="column">
        <carrousel :page="'meedoen_woongemeenschap'" />
      </div>
    </div>
  </div>
</template>

<script>
import quote from "@/views/Components/Quote.vue";
export default {
  components: {
    quote,
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
