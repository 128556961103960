<style scoped>
.customTitle {
  font-weight: bold;
}

.customBox {
  border-style: solid;
}
</style>

<template>
  <div>
    <quote
      quote="Alles wat je aandacht geeft groeit daarom besteden wij graag aandacht aan
      het mooi maken van onze leefomgeving"
    ></quote>

    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Beschut wonen</div>
          Vanaf 1 november 2024 kun je bij Het Dagelijks Bestaan Beschut Wonen.
          We hebben een fijn huis op vijf minuten loopafstand van onze
          hoofdlocatie. Hier kun je in een of twee jaar de stappen maken die
          nodig zijn om zelfstandig te gaan wonen. Je kunt leren koken, krijgt
          ondersteuning in het ordenen van je huishoudelijke taken en financiën.
          Er is ook ruimte voor zelfontwikkeling en zingeving: het ontdekken van
          je talenten, hoe om te gaan met vriendschappen en (familie)relaties en
          andere levensvragen. Meermaals per week wordt er samen met je
          huisgenoten en een begeleider gezamenlijk gegeten. Naast de
          groepsbegeleiding heb je ook een individueel begeleider. Jongeren die
          bij ons wonen, hebben een zinvolle daginvulling, bijvoorbeeld een
          studie, (vrijwilligers)werk of dagbesteding.
          <br />
          <br />
          <div class="customTitle">Voor wie is het?</div>
          Jongeren tussen de 18 en 27 jaar kunnen bij ons komen wonen. De meeste
          jongeren die bij ons komen wonen, willen graag zelfstandig leren
          worden. Bij ons kun je oefenen met sociale vraagstukken, (gezond)
          leren koken en leren om jezelf en je huis te verzorgen. In het huis
          wonen drie jongeren, je hebt je eigen slaap-/woonruimte en een
          gedeelde badkamer en keuken. In de keuken kan gezamenlijk gegeten
          worden.
        </div>
      </div>
      <div class="column">
        <carrousel :page="'beschutwonen'" />
      </div>
    </div>

    <div class="box">
      <div class="customTitle">Hoe ziet het traject er uit?</div>
      Samen in een huis wonen met jongeren om daar stappen te maken richting
      zelfstandigheid, dat is natuurlijk niet niets. Wanneer je net nieuw komt
      wonen, besteden we extra aandacht aan het leren kennen van het huis, je
      huisgenoten en de begeleiding. Er is Dagelijks een contact moment, waar je
      vragen kan stellen of een praatje kan maken. In deze periode is er
      aandacht voor het formuleren van je leerdoelen en het thuis komen.
      <br />
      <br />
      Wanneer je huisgenoten, je huis en Het Dagelijks Bestaan wat beter kent,
      wordt er gewerkt aan je persoonlijke leerdoelen. Voor de een gaat het
      vooral om sociale vraagstukken, terwijl een ander misschien meer hulp
      nodig heeft in praktische vaardigheden. Wanneer jijzelf en de begleiding
      het idee hebben dat je toe bent aan een volgende stap, helpen we je actief
      in het zoeken van een vervolgwoning. Dit kan ook een ‘omklapwoning’ zijn,
      waarbij je nog een periode ambulant begeleid wordt door ons (of een andere
      organisatie).
    </div>
    <div class="box customBox">
      <span class="customTitle">Hoe kom je bij ons terecht?</span> <br />
      Om bij ons te kunnen wonen, heb je een indicatie van Beschut Wonen nodig,
      die krijg je via de regionale toegang van Beschermd Wonen Deventer. Je
      kunt hier meer informatie over lezen via
      <a href="https://www.deventer.nl/beschermdwonen"
        >www.deventer.nl/beschermdwonen</a
      >
      of mailen naar
      <a href="mailto:beschermdwonen@deventer.nl">beschermdwonen@deventer.nl</a>
      Zodra je indicatie rond is, kun je bij ons een kennismakingsgesprek
      aanvragen. We kijken dan of er een goede klik is tussen jouw vraag en de
      begeleiding die wij bieden. Mocht je na het kennismakingsgesprek willen
      komen, volgt het intakegesprek. Dan kijken we inhoudelijk hoe we met jouw
      ontwikkelvragen gaan begeleiden en naar hoe het wonen er voor jou uit komt
      te zien.
    </div>
  </div>
</template>

<script>
import quote from "@/views/Components/Quote.vue";

export default {
  components: {
    quote: quote,
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
