var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns is-fullhd"},[_c('div',{staticClass:"column"},[_vm._m(0),_c('div',{staticClass:"columns is-fullhd"},[_c('div',{staticClass:"column"},[_c('article',{staticClass:"picturebackground pointer notification is-primary",style:('background-image: url(' +
              _vm.getPictureLink('werkplaatsen_sport') +
              ')'),on:{"click":function($event){return _vm.$router.push({ name: 'sport-en-spel' })}}},[_c('p',{staticClass:"title"},[_vm._v("Sport & Spel")]),_c('p',{staticClass:"subtitle"},[_vm._v("Sociale spelen")])])]),_c('div',{staticClass:"column"},[_c('article',{staticClass:"picturebackground pointer notification is-primary",style:('background-image: url(' +
              _vm.getPictureLink('werkplaatsen_hetleerhuis') +
              ')'),on:{"click":function($event){return _vm.$router.push({ name: 'het-leerhuis' })}}},[_c('p',{staticClass:"title"},[_vm._v("Het leerhuis")]),_c('p',{staticClass:"subtitle"})])]),_c('div',{staticClass:"column"},[_c('article',{staticClass:"picturebackground pointer notification is-primary",style:('background-image: url(' +
              _vm.getPictureLink('werkplaatsen_horeca') +
              ')'),on:{"click":function($event){return _vm.$router.push({ name: 'horeca' })}}},[_c('p',{staticClass:"title"},[_vm._v("Horeca")]),_c('p',{staticClass:"subtitle"},[_vm._v("Koken en bakken")])])])]),_c('div',{staticClass:"columns is-fullhd"},[_c('div',{staticClass:"column"},[_c('article',{staticClass:"picturebackground pointer notification is-primary",style:('background-image: url(' +
              _vm.getPictureLink('werkplaatsen_buiten') +
              ')'),on:{"click":function($event){return _vm.$router.push({ name: 'buiten' })}}},[_c('p',{staticClass:"title"},[_vm._v("Buiten")]),_c('p',{staticClass:"subtitle"},[_vm._v("Tuin en klus")])])]),_c('div',{staticClass:"column"},[_c('article',{staticClass:"picturebackground pointer notification is-primary",style:('background-image: url(' +
              _vm.getPictureLink('werkplaatsen_kunst') +
              ')'),on:{"click":function($event){return _vm.$router.push({ name: 'kunst' })}}},[_c('p',{staticClass:"title"},[_vm._v("Kunst")]),_c('p',{staticClass:"subtitle"})])])])]),_c('div',{staticClass:"column"},[_c('carrousel',{attrs:{"page":'werkplaatsen_alle_werkplaatsen'}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"bold"},[_vm._v("Werkplaatsen")]),_vm._v(" Wij geloven in de kracht van ervaringsleren, in de verbinding tussen je hoofd, hart en handen. Wekelijks zijn er verschillende werkplaatsen en onderdelen die terugkerend op het programma staan. Het aanbod verandert geregeld, zodat we zo goed mogelijk aansluiten bij de groep jongeren die er aanwezig is. Hieronder zie je een lijst met de werkplaatsen die (afwisselend) aangeboden worden, klik door voor meer informatie. ")])}]

export { render, staticRenderFns }