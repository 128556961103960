<template>
  <div>
    <quote
      quote="Je leeft niet alleen voor jezelf, alles wat je doet heeft invloed op het
      grotere geheel. Iedereen heeft iets te doen in de wereld, iedereen heeft
      verantwoordelijkheid voor zijn omgeving. Ieder mens heeft zijn eigen
      levens verhaal, samen schrijven wij ons verhaal."
    ></quote>
    <br />
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Organisatiestructuur</div>
          Alle betrokkenen bij Het Dagelijks Bestaan zijn onderling verbonden
          door het maatschappelijke doel van de stichting. Zingeving staat voor
          iedereen centraal: jongeren, medewerkers, vrijwilligers en vrienden.
          Het sociale netwerk van Het Dagelijks Bestaan staat bekend om zijn
          openheid en gastvrijheid. Iedereen die wil bijdragen is welkom! Het
          Dagelijks Bestaan kent een platte organisatiestructuur waarin
          belangrijke beslissingen gezamenlijk worden voorbereid, waarna de raad
          van bestuur het besluit formaliseert. De raad van toezicht bewaakt en
          keurt het stichtingsbeleid goed. Operationele beslissingen worden zo
          laag mogelijk in de organisatie genomen. Gelijkwaardigheid en
          autonomie van medewerkers zijn belangrijke waarden. Doel is een
          effectieve, flexibele organisatie waarin medewerkers zich kunnen
          ontplooien. Een deel van het werk bij Het Dagelijks Bestaan berust op
          vrijwillige inzet. Dit geldt uiteraard voor de vrijwilligers, maar
          deels ook voor de vaste groep van medewerkers. De beloningsstructuur
          is gelijkwaardig van opzet.
          <br />
          <br />
          De Stichting Het Dagelijks Bestaan positioneert zich op het snijvlak
          van jeugdhulpverlening, sociale activering, zingeving, onderwijs en
          arbeid. Het Dagelijks Bestaan stelt zich ten doel om jongeren tussen
          14 en 27 jaar een kleinschalig leer- en ontwikkeltraject te bieden en
          hen te begeleiden op de weg terug naar onderwijs en/of werk en/of
          (langdurige) hulpverlening. <br />
          <br />

          <div class="bold">ANBI</div>
          Het Dagelijks Bestaan heeft als stichting de status van een algemeen
          nut beogende instelling (ANBI). Door deze status hoeft het Dagelijks
          Bestaan geen schenkbelasting over haar verkregen donaties af te dragen
          en zijn donaties van particulieren aan Het Dagelijks Bestaan onder
          bepaalde voorwaarden aftrekbaar bij de aangifte inkomstenbelasting.
          <br />
          <br />
          Voor meer informatie klik hier

          <a
            href="https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/zakelijk/bijzondere_regelingen/goede_doelen/algemeen_nut_beogende_instellingen/welke_belastingvoordelen_heeft_een_anbi"
            >www.belastingdienst/anbibelastingvoordelen</a
          >
          <br />
          <br />
          <a @click="openPdfLink('ANBIverantwoording')"
            >Klik hier voor onze meest recente ANBI verantwoordingsformulier</a
          ><br />
          <a @click="openPdfLink('activiteitenverslag')">
            Klik hier voor onze meest recente activiteitenverslag</a
          ><br />
          <a @click="openPdfLink('jaarrekening')"
            >Klik hier voor onze meest recente jaarrekening</a
          ><br />
          <a @click="openPdfLink('beleidsplan')"
            >Klik hier voor ons actuele beleidsplan</a
          ><br />
          <br />

          <div class="bold">Raad van bestuur</div>
          De raad van bestuur bestaat uit het volgende lid:

          <div class="content">
            <ul>
              <li>Naam: Tim ten Wolde</li>
              <li>Functie: Directeur-bestuurder</li>
            </ul>
          </div>

          <div class="bold">Raad van toezicht</div>
          <div class="content">
            De raad van toezicht bestaat uit de volgende leden:

            <ul>
              <li>Naam: W. Hetebrij</li>
              <li>Deelgebied: Voorzitter & Remuneratie</li>
              <li>Aangesteld tot: april 2025</li>
              <li>Herkiesbaar: nee</li>
            </ul>
            <ul>
              <li>Naam: C. Koopmans</li>
              <li>Deelgebied: Zorg & kwaliteit</li>
              <li>Aangesteld tot: april 2026</li>
              <li>Herkiesbaar: nee</li>
            </ul>
            <ul>
              <li>Naam: E.B.J. Dievelaar</li>
              <li>Deelgebied: Secretaris, remuneratie & financiën</li>
              <li>Aangesteld tot: april 2027</li>
              <li>Herkiesbaar: nee</li>
            </ul>
            <ul>
              <li>Naam: H.A. Poppenk</li>
              <li>Deelgebied: Zorg & kwaliteit</li>
              <li>Aangesteld tot: januari 2027</li>
              <li>Herkiesbaar: ja</li>
            </ul>
            <ul>
              <li>Naam: G. Koppes</li>
              <li>Deelgebied: Remuneratie & financiën</li>
              <li>Aangesteld tot: januari 2028</li>
              <li>Herkiesbaar: ja</li>
            </ul>
            <br />
          </div>
        </div>
      </div>
      <div class="column">
        <carrousel :page="'overons_organisatie'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {
    openPdfLink(type) {
      if (type === "ANBIverantwoording") {
        window.open(
          "/publications/Het Dagelijks Bestaan anbi-publicatieformulier-2024.pdf",
          "_blank"
        );
      }

      if (type === "activiteitenverslag") {
        window.open(
          "/publications/Het Dagelijks Bestaan activiteitenverslag 2024.pdf",
          "_blank"
        );
      }

      if (type === "jaarrekening") {
        window.open(
          "/publications/Het Dagelijks Bestaan Jaarrekening 2023.pdf",
          "_blank"
        );
      }

      if (type === "beleidsplan") {
        window.open(
          "/publications/Het Dagelijks Bestaan meerjarenplan 2025-2028.pdf",
          "_blank"
        );
      }
    },
  },
  mounted() {},
};
</script>
