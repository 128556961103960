<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <div class="box">
          <div class="bold">Buiten</div>
          Op de klusochtend wordt er samen met vrijwilligers, buren en de
          jongeren gestart rondom een vuurtje met een gebakken ei. In de ochtend
          worden de klussen verdeeld. Het opknappen van meubels, het leggen van
          een straatje of het opruimen van de buitenruimte, er is altijd genoeg
          te doen. Naast het werk op ons eigen terrein, doen we ook regelmatig
          een klus voor iemand in de wijk die dit nodig heeft. <br />
          <br />
          <div class="bold">Tuin</div>
          Naast het klussen buiten, is er ook veel tuinwerk te doen. We hebben
          een moestuin, veel inheemse bloemensoorten, een heus wilgenwoud en
          veel andere mooie plekken. Ieder seizoen vraagt om andere
          werkzaamheden, van zaaiwerk tot omspitten. Er wordt creatief
          getuinierd en er ook worden er producten gemaakt met wat er groeit in
          de tuin (van liters soep tot bloementhee).
        </div>
      </div>
      <div class="column">
        <carrousel :page="'werkplaatsen_buiten'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
