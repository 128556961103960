import Vue from "vue";
import VueRouter from "vue-router";
import AppBase from "@/views/AppBase.vue";
import Home from "@/views/Home.vue";
import FourZeroFour from "../views/FourZeroFour.vue";
import Login from "../views/Auth/Login.vue";
import Passwordreset from "../views/Auth/Passwordreset.vue";
import PasswordChange from "../views/Auth/PasswordChange.vue";

import Dagbesteding from "@/views/NavButtons/Dagbesteding.vue";

import OverOnsBase from "@/views/NavButtons/OverOnsBase.vue";
import OverOns from "@/views/NavButtons/OverOns/OverOns.vue";
import Geschiedenis from "@/views/NavButtons/OverOns/Geschiedenis.vue";
import Klachtenprocedure from "@/views/NavButtons/OverOns/Klachtenprocedure.vue";
import Grondsteenspreuk from "@/views/NavButtons/OverOns/Grondsteenspreuk.vue";
import OnzeWoorden from "@/views/NavButtons/OverOns/OnzeWoorden.vue";
import Visie from "@/views/NavButtons/OverOns/Visie.vue";
import ZakelijkeInformatie from "@/views/NavButtons/OverOns/ZakelijkeInformatie.vue";

import BeschermdWonen from "@/views/NavButtons/BeschermdWonen.vue";
import BeschutWonen from "@/views/NavButtons/BeschutWonen.vue";

import WerkplaatsenBase from "@/views/NavButtons/WerkplaatsenBase.vue";
import Werkplaatsen from "@/views/NavButtons/Werkplaatsen/Werkplaatsen.vue";
import Buiten from "@/views/NavButtons/Werkplaatsen/Buiten.vue";
import HetLeerhuis from "@/views/NavButtons/Werkplaatsen/HetLeerhuis.vue";
import Horeca from "@/views/NavButtons/Werkplaatsen/Horeca.vue";
import Kunst from "@/views/NavButtons/Werkplaatsen/Kunst.vue";
import SportEnSpel from "@/views/NavButtons/Werkplaatsen/SportEnSpel.vue";
import GastAanTafel from "@/views/NavButtons/Werkplaatsen/GastAanTafel.vue";

import MeedoenBase from "@/views/NavButtons/MeedoenBase.vue";
import Meedoen from "@/views/NavButtons/Meedoen/Meedoen.vue";
import Vacature from "@/views/NavButtons/Meedoen/Vacature.vue";
import Donaties from "@/views/NavButtons/Meedoen/Donaties.vue";
import Doneren from "@/views/NavButtons/Meedoen/Doneren.vue";
import DonatieBedankt from "@/views/NavButtons/Meedoen/DonatieBedankt.vue";
import StageLopen from "@/views/NavButtons/Meedoen/StageLopen.vue";
import VriendenVan from "@/views/NavButtons/Meedoen/VriendenVan.vue";
import Vrijwilligers from "@/views/NavButtons/Meedoen/Vrijwilligers.vue";
import WerkenBij from "@/views/NavButtons/Meedoen/WerkenBij.vue";
import Woongemeenschap from "@/views/NavButtons/Meedoen/Woongemeenschap.vue";
import OproepNieuweBewoner from "@/views/NavButtons/Meedoen/OproepNieuweBewoner.vue";

import Contact from "@/views/NavButtons/Contact.vue";
import ContactFormulierVerzonden from "@/views/NavButtons/ContactFormulierSuccess.vue";

import AdminBase from "@/views/NavButtons/Admin/AdminBase.vue";
import Admin from "@/views/NavButtons/Admin/AdminButtons.vue";
import AdminFotos from "@/views/NavButtons/Admin/AdminFotos.vue";
import AdminFotoUpload from "@/views/NavButtons/Admin/AdminFotoUpload.vue";
import AdminContactForm from "@/views/NavButtons/Admin/AdminContactFormulier.vue";

import GebruikersBase from "@/views/NavButtons/Admin/Users/GebruikersBase.vue";
import GebruikersIndex from "@/views/NavButtons/Admin/Users/GebruikersIndex.vue";
import GebruikerToevoegen from "@/views/NavButtons/Admin/Users/GebruikerToevoegen.vue";
import GebruikerAanpassen from "@/views/NavButtons/Admin/Users/GebruikerAanpassen.vue";

import PostalCodeBase from "@/views/NavButtons/Admin/PostalCodes/PostalCodeBase.vue";
import PostalCodeIndex from "@/views/NavButtons/Admin/PostalCodes/PostalCodeIndex.vue";
import AddPostalCode from "@/views/NavButtons/Admin/PostalCodes/AddPostalCode.vue";
import EditPostalCode from "@/views/NavButtons/Admin/PostalCodes/EditPostalCode.vue";

import BlacklistIndex from "@/views/NavButtons/Admin/Blacklist/BlacklistIndex.vue";
import BlacklistBase from "@/views/NavButtons/Admin/Blacklist/BlacklistBase.vue";
import AddBlacklistItem from "@/views/NavButtons/Admin/Blacklist/AddBlacklistItem.vue";
import EditBlacklistItem from "@/views/NavButtons/Admin/Blacklist/EditBlacklistItem.vue";

import AdminSoepBase from "@/views/NavButtons/Admin/Soep/SoepBase";
import AdminSoepIndex from "@/views/NavButtons/Admin/Soep/SoepIndex";
import AdminProduct from "@/views/NavButtons/Admin/Soep/Product";
import AdminProductEdit from "@/views/NavButtons/Admin/Soep/AdminProductEdit";

import KlantenBase from "@/views/NavButtons/Admin/Klanten/KlantenBase.vue";
import KlantenIndex from "@/views/NavButtons/Admin/Klanten/KlantenIndex.vue";
import EditKlant from "@/views/NavButtons/Admin/Klanten/EditKlant.vue";
import Klant from "@/views/NavButtons/Admin/Klanten/Klant.vue";

import BezorglijstenBase from "@/views/NavButtons/Admin/Bezorglijsten/BezorglijstBase.vue";
import BezorglijstenIndex from "@/views/NavButtons/Admin/Bezorglijsten/BezorglijstIndex.vue";

import LeverdagBase from "@/views/NavButtons/Admin/Bezorglijsten/LeverdagBase.vue";
import Leverdag from "@/views/NavButtons/Admin/Bezorglijsten/Leverdag.vue";
import CreateLeverdagBezorglijst from "@/views/NavButtons/Admin/Bezorglijsten/LeverdagBezorglijsten/BezorgLijstCreate.vue";

import BestellingenBase from "@/views/NavButtons/Admin/Bestellingen/BestellingenBase.vue";
import BestellingenIndex from "@/views/NavButtons/Admin/Bestellingen/BestellingenIndex.vue";
import EditBestelling from "@/views/NavButtons/Admin/Bestellingen/EditBestelling.vue";
import AddBestelling from "@/views/NavButtons/Admin/Bestellingen/AddBestelling.vue";
import AddVasteKlantenBestelling from "@/views/NavButtons/Admin/Bestellingen/AddVasteKlantenBestelling.vue";

import BestellingendagIndex from "@/views/NavButtons/Admin/Besteldagen/BestellingendagIndex.vue";
import BestellingendagBase from "@/views/NavButtons/Admin/Besteldagen/BestellingendagBase.vue";
import AddBestellingdag from "@/views/NavButtons/Admin/Besteldagen/AddBestellingdag.vue";
import EditBestellingdag from "@/views/NavButtons/Admin/Besteldagen/EditBestellingdag.vue";

import DagelijkseSoepBase from "@/views/NavButtons/DagelijkseSoep/DagelijkseSoepBase";
import DagelijkseSoepLanding from "@/views/NavButtons/DagelijkseSoep/DagelijkseSoepLanding";
import DagelijkseSoepBesteld from "@/views/NavButtons/DagelijkseSoep/DagelijkseSoepBesteld";
import DagelijkseSoepEditOrder from "@/views/NavButtons/DagelijkseSoep/DagelijkseSoepEditOrder";
import DagelijkseSoepProduct from "@/views/NavButtons/DagelijkseSoep/DagelijkseSoepProduct";
import DagelijkseSoepCheckout from "@/views/NavButtons/DagelijkseSoep/DagelijkseSoepCheckout";

import SettingsBase from "@/views/NavButtons/Admin/Instellingen/InstellingenBase";
import Settings from "@/views/NavButtons/Admin/Instellingen/Instellingen";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: AppBase,
    children: [
      { path: "", component: Home, name: "home" },
      { path: "dagprogramma", component: Dagbesteding, name: "dagbesteding" },
      {
        path: "inloggen",
        name: "inloggen",
        component: Login,
      },
      {
        path: "wachtwoord-resetten",
        name: "wachtwoord-resetten",
        component: Passwordreset,
      },
      {
        path: "wachtwoord-resetten/:email/:token",
        name: "wachtwoord-veranderen",
        component: PasswordChange,
      },
      {
        path: "dagelijkse-soep",
        component: DagelijkseSoepBase,
        children: [
          {
            path: "",
            component: DagelijkseSoepLanding,
            name: "dagelijkse-soep-landing",
          },
          {
            path: "bestelling-aanpassen/:uuid",
            component: DagelijkseSoepEditOrder,
            name: "dagelijkse-soep-bestelling-aanpassen",
          },
          {
            path: "bestellen",
            component: DagelijkseSoepCheckout,
            name: "dagelijkse-soep-checkout",
          },
          {
            path: "bestelling-ontvangen",
            component: DagelijkseSoepBesteld,
            name: "dagelijkse-soep-besteld",
          },
          {
            path: ":product",
            component: DagelijkseSoepProduct,
            name: "dagelijkse-soep-product",
          },
        ],
      },
      {
        path: "admin",
        component: AdminBase,
        children: [
          {
            path: "",
            component: Admin,
            name: "admin",
          },
          {
            path: "foto-upload",
            component: AdminFotoUpload,
            name: "admin-foto-upload",
          },
          {
            path: "fotos",
            component: AdminFotos,
            name: "admin-fotos",
          },
          {
            path: "contactformulier",
            component: AdminContactForm,
            name: "admin-contactformulier",
          },
          {
            path: "klanten",
            component: KlantenBase,
            children: [
              {
                path: "",
                component: KlantenIndex,
                name: "klanten-index",
              },
              {
                path: "aanpassen/:klant",
                component: EditKlant,
                name: "klant-aanpassen",
              },
              {
                path: ":klant",
                component: Klant,
                name: "klant",
              },
            ],
          },
          {
            path: "bezorglijsten",
            component: BezorglijstenBase,
            children: [
              {
                path: "",
                component: BezorglijstenIndex,
                name: "bezorglijsten-index",
              },
              {
                path: "Leverdag/:leverdag",
                component: LeverdagBase,
                children: [
                  {
                    path: "",
                    component: Leverdag,
                    name: "leverdag-bekijken",
                  },
                  {
                    path: "bezorglijst-toevoegen",
                    component: CreateLeverdagBezorglijst,
                    name: "create_new_leverdag_bezorglijst",
                  },
                ],
              },
            ],
          },
          {
            path: "bestellingen",
            component: BestellingenBase,
            children: [
              {
                path: "",
                component: BestellingenIndex,
                name: "bestellingen-index",
              },
              {
                path: "toevoegen",
                component: AddBestelling,
                name: "bestelling-toevoegen",
              },
              {
                path: "vaste-klant-bestelling",
                component: AddVasteKlantenBestelling,
                name: "vaste-klant-bestelling-toevoegen",
              },
              {
                path: "aanpassen/:bestelling",
                component: EditBestelling,
                name: "bestelling-aanpassen",
              },
            ],
          },
          {
            path: "bestellingdagen",
            component: BestellingendagBase,
            children: [
              {
                path: "",
                component: BestellingendagIndex,
                name: "bestellingsdagen-index",
              },
              {
                path: "toevoegen",
                component: AddBestellingdag,
                name: "bestellingsdag-toevoegen",
              },
              {
                path: "aanpassen/:orderday",
                component: EditBestellingdag,
                name: "bestellingsdag-aanpassen",
              },
            ],
          },
          {
            path: "soepen",
            component: AdminSoepBase,
            children: [
              {
                path: "",
                component: AdminSoepIndex,
                name: "soep-index",
              },
              {
                path: ":product",
                component: AdminProduct,
                name: "admin-product",
              },
              {
                path: "toevoegen",
                component: AddBlacklistItem,
                name: "soep-toevoegen",
              },
              {
                path: "aanpassen/:product",
                component: AdminProductEdit,
                name: "product-aanpassen",
              },
            ],
          },
          {
            path: "blacklist",
            component: BlacklistBase,
            children: [
              {
                path: "",
                component: BlacklistIndex,
                name: "blacklist-index",
              },
              {
                path: "toevoegen",
                component: AddBlacklistItem,
                name: "blacklist-toevoegen",
              },
              {
                path: "aanpassen/:blacklist",
                component: EditBlacklistItem,
                name: "blacklist-aanpassen",
              },
            ],
          },
          {
            path: "instellingen",
            component: SettingsBase,
            children: [
              {
                path: "",
                component: Settings,
                name: "settings-index",
              },
            ],
          },
          {
            path: "postcodes",
            component: PostalCodeBase,
            children: [
              {
                path: "",
                component: PostalCodeIndex,
                name: "postcode-index",
              },
              {
                path: "toevoegen",
                component: AddPostalCode,
                name: "postcode-toevoegen",
              },
              {
                path: "aanpassen/:postcode",
                component: EditPostalCode,
                name: "postcode-aanpassen",
              },
            ],
          },
          {
            path: "gebruikers",
            component: GebruikersBase,
            children: [
              {
                path: "",
                component: GebruikersIndex,
                name: "admin-gebruikers",
              },
              {
                path: "toevoegen",
                component: GebruikerToevoegen,
                name: "gebruiker-toevoegen",
              },
              {
                path: "aanpassen/:user",
                component: GebruikerAanpassen,
                name: "gebruiker-aanpassen",
              },
            ],
          },
        ],
      },
      {
        path: "klachtenprocedure",
        component: Klachtenprocedure,
        name: "klachtenprocedure",
      },
      {
        path: "over-ons",
        component: OverOnsBase,
        children: [
          {
            path: "",
            component: OverOns,
            name: "over-ons",
          },
          {
            path: "visie",
            component: Visie,
            name: "visie",
          },
          {
            path: "geschiedenis",
            component: Geschiedenis,
            name: "geschiedenis",
          },
          {
            path: "grondsteenspreuk",
            component: Grondsteenspreuk,
            name: "grondsteenspreuk",
          },
          {
            path: "onze-woorden",
            component: OnzeWoorden,
            name: "onze-woorden",
          },
          {
            path: "organisatie",
            component: ZakelijkeInformatie,
            name: "zakelijke-informatie",
          },
        ],
      },

      {
        path: "beschermd-wonen",
        component: BeschermdWonen,
        name: "beschermd-wonen",
      },
      {
        path: "beschut-wonen",
        component: BeschutWonen,
        name: "beschut-wonen",
      },
      {
        path: "werkplaatsen",
        component: WerkplaatsenBase,
        children: [
          { path: "", component: Werkplaatsen, name: "werkplaatsen" },
          {
            path: "sport-en-spel",
            component: SportEnSpel,
            name: "sport-en-spel",
          },
          {
            path: "het-leerhuis",
            component: HetLeerhuis,
            name: "het-leerhuis",
          },
          { path: "horeca", component: Horeca, name: "horeca" },
          { path: "buiten", component: Buiten, name: "buiten" },
          { path: "kunst", component: Kunst, name: "kunst" },
          {
            path: "huis-en-haard",
            component: GastAanTafel,
            name: "gast-aan-tafel",
          },
        ],
      },
      {
        path: "meedoen",
        component: MeedoenBase,
        children: [
          {
            path: "",
            component: Meedoen,
            name: "meedoen",
          },
          {
            path: "werken-bij",
            component: WerkenBij,
            name: "werken-bij",
          },
          {
            path: "stage-lopen",
            component: StageLopen,
            name: "stage-lopen",
          },
          {
            path: "vrijwilligers",
            component: Vrijwilligers,
            name: "vrijwilligers",
          },
          {
            path: "woongemeenschap",
            component: Woongemeenschap,
            name: "woongemeenschap",
          },
          {
            path: "woongemeenschap/nieuwe-bewoner",
            component: OproepNieuweBewoner,
            name: "nieuwe-bewoner",
          },
          {
            path: "donaties",
            component: Donaties,
            name: "donaties",
          },
          {
            path: "doneren",
            component: Doneren,
            name: "doneren",
          },
          {
            path: "donaties/bedankt-voor-uw-donatie",
            component: DonatieBedankt,
            name: "donatie-bedankt",
          },
          {
            path: "vrienden-van",
            component: VriendenVan,
            name: "vrienden-van",
          },
          {
            path: "werken-bij/:vacature",
            component: Vacature,
            name: "vacature",
          },
        ],
      },
      { path: "contact", component: Contact, name: "contact" },
      {
        path: "contact-formulier-verzonden",
        component: ContactFormulierVerzonden,
        name: "contact-formulier-verzonden",
      },
    ],
  },
  {
    path: "*",
    component: FourZeroFour,
    name: "404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "is-active",
  linkExactActiveClass: "is-active",
  routes,
});

export default router;
